import React from "react";
import { Link } from "react-router-dom";

function Footer() {
    return (
        <div className="footer bg-dark">
            <h5 className='white center mono'>© Portfolio - EVP 2023</h5>

        </div>
       
        
    )
}

export default Footer